// config.js

const bizIcon = "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg";
const imageOne = "https://static.wixstatic.com/media/13eefd27e5a543a688fff990b0adb35b.jpg/v1/fill/w_1253,h_678,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/13eefd27e5a543a688fff990b0adb35b.jpg";
const imageTwo = "https://static.wixstatic.com/media/74f558_993f041afc4045c69c824d6022d78ca0~mv2_d_4000_2667_s_4_2.jpeg/v1/fill/w_344,h_726,fp_0.50_0.50,q_80,usm_0.66_1.00_0.01,enc_auto/74f558_993f041afc4045c69c824d6022d78ca0~mv2_d_4000_2667_s_4_2.jpeg";
const imageThree = "https://static.wixstatic.com/media/74f558_4ef3f401e4a2400a8979c42d1a7ba1a7~mv2_d_5760_3840_s_4_2.jpeg/v1/fill/w_340,h_726,fp_0.50_0.50,q_80,usm_0.66_1.00_0.01,enc_auto/74f558_4ef3f401e4a2400a8979c42d1a7ba1a7~mv2_d_5760_3840_s_4_2.jpeg";
const imageFour = "https://static.wixstatic.com/media/74f558_9b1cc2c7424d47bf83b890c15ed8127f~mv2_d_5625_3750_s_4_2.jpg/v1/fill/w_340,h_726,fp_0.50_0.50,q_80,usm_0.66_1.00_0.01,enc_auto/74f558_9b1cc2c7424d47bf83b890c15ed8127f~mv2_d_5625_3750_s_4_2.jpg";
const imageFive = "https://static.wixstatic.com/media/74f558_0e46dc42786945daad2d2535524461c3~mv2_d_6048_4032_s_4_2.jpeg/v1/fill/w_313,h_420,fp_0.43_0.30,q_80,usm_0.66_1.00_0.01,enc_auto/74f558_0e46dc42786945daad2d2535524461c3~mv2_d_6048_4032_s_4_2.jpeg";

const socialMediaLink = 'https://www.samyspainting.net/';

export const siteConfig = {
  title: "Samy's Painting Services",
  metaDescription: "Samy's Painting Services",
  theme: {
    primaryColor: "orange-500",
    secondaryColor: "gray-200",
    accentColor: "text-orange-500"
  },
  logo: {
    src: bizIcon,
    alt: "Samy's Painting Services",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Samy's Painting Services",
    headline: "Premium Painting Services",
    address: "1234 Main Street, Atlanta, GA 30301",
    description: "Quality painting services for your lovely home."
  },
  images: {
    business: {
      src: imageOne,
      alt: "Premium Painting Services",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
      description:
        "With 20 years of experience, Samy's Painting Services delivers top-quality painting solutions for residential, commercial, remodeling, and new construction projects throughout the Atlanta metro area.",
    },
    {
      description:
        "Our team of skilled painters is equipped to handle projects of any size and complexity, ensuring exceptional results and a seamless, hassle-free experience for our clients.",
    },
    {
      description:
        "We are committed to exceeding expectations by providing professional, reliable, and high-quality painting services that transform spaces and bring your vision to life.",
    },
  ],
  
  services: {
    sectionTitle: "Our Painting Services",
    description: "At CK Rondon Painting, LLC, we deliver high-quality painting and surface care services tailored for both residential and commercial spaces. With over 10 years of experience, we focus on exceptional workmanship, whether refreshing interiors, updating exteriors, or adding specialty finishes. Explore our full list of services and see how we bring your vision to life.",
    callouts: [
      {
        name: 'Residential Painting',
        description: 'Transform rooms with vibrant, professional finishes.',
        details: 'From single rooms to full interior makeovers, our skilled team uses high-quality paints to give your space a fresh, modern look.',
        imageSrc: imageTwo,
        imageAlt: 'Modern interior with fresh paint',
      },
      {
        name: 'Commercial Painting',
        description: 'Refinish cabinets for an updated, stylish look.',
        details: 'We offer cabinet painting and staining to modernize kitchens, bathrooms, or office spaces, providing custom color matching and high-quality finishes.',
        imageSrc: imageThree,
        imageAlt: 'Stylish painted kitchen cabinets',
      },
      {
        name: 'Remodeling Projects',
        description: 'Deep clean outdoor surfaces for a refreshed look.',
        details: 'Our high-pressure washing removes dirt, grime, and mildew, restoring driveways, decks, and other surfaces to their original beauty.',
        imageSrc: imageFour,
        imageAlt: 'Driveway being pressure washed',
      }
    ],
    otherServices: [
      "Kitchen Painting",
      "Bathrooms Painting",
      "Interiors",
      "Additions",
      "Exteriors",
      "Sunrooms",
    ]
  },  
  about: {
    sectionTitle: "About Us",
    description: "We is a well-established painting business with over 20 years of experience serving the Atlanta metro area. They provide a comprehensive range of services, including residential and commercial painting, as well as work for remodeling projects and new construction. Their website is designed to attract new clients and highlight their past work. In Samy's Painting Services, stands out from the competition through its depth of experience and broad service offerings.",
    image: {
      src: imageFive,
      alt: "CK Rondon Painting team in action",
      width: 2432,
      height: 1442
    }
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "1234 Main Street, Atlanta, GA 30301, USA",
    googleMapsEmbedUrl: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3347.306193214849!2d-84.3880!3d33.7490!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f5047123456789%3A0x1234567890abcdef!2s1234%20Main%20St%2C%20Atlanta%2C%20GA%2030301%2C%20USA!5e0!3m2!1sen!2sph!4v1710407450828!5m2!1sen!2sph",
    subCategories: [
      { name: 'Apple Maps', url: 'https://maps.apple.com/?address=1234%20Main%20St,%20Atlanta,%20GA%2030301&ll=33.7490,-84.3880&q=1234%20Main%20St,%20Atlanta,%20GA%2030301' },
      { name: 'Bing Maps', url: 'https://www.bing.com/maps?q=1234+Main+St,+Atlanta,+GA+30301&form=HDRSC6&sp=1&pq=1234+main+st+atlanta+ga+30301&sc=0-0&qs=n&sk=&cvid=EDFC0FE5A0E74E8DB130342A842AA10C' },
      { name: 'Google Earth', url: 'https://earth.google.com/web/search/1234+Main+St,+Atlanta,+GA+30301,+USA/@33.7490,-84.3880,15.26523397a,814.57589625d,35y,0h,0t,0r' },
      { name: 'Google Maps', url: 'https://maps.app.goo.gl/4Y2YfHGWxgrC8tTE7' }
    ]
  },
  contact: {
    sectionTitle: "Schedule a Free Consultation for Your Next Painting Project",
    description: "Ready to transform your space with a fresh coat of paint? Get in touch with us today to schedule a free consultation and bring your vision to life.",
    socialLinks: {
      facebook: socialMediaLink,
      instagram: socialMediaLink,
      twitter: socialMediaLink,
      youtube: socialMediaLink,
      tiktok: socialMediaLink
    },
    contactNumbers: ["678-615-6577", "404-956-3997"],
    logo: bizIcon,
    emailRecipient: "samelylopez@gmail.com"
  },
  footer: {
    bizSolutionsLogo: "https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg"
  }
};
